<template>
  <div>
    <v-fade-transition hide-on-leave>
      <v-layout row wrap d-flex align-center>
        <v-flex
          class="pl-0"
          xs12
          sm3
          md3
          :class="$vuetify.breakpoint.smAndUp ? 'pr-0' : ''"
        >
          <v-select
            v-model="selectedPhoneCountryCode"
            :items="countries"
            cache-items
            :label="$vuetify.lang.t('$vuetify.settings.countryCode')"
            item-text="country_name"
            item-value="country_name"
            return-object
            outlined
            hide-details
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'border-top-right-radius: 0; border-bottom-right-radius: 0;'
                : ''
            "
            dense
          >
            <template v-slot:selection="{ item }">
              <span
                :class="
                  `flag-icon flag-icon-${item.country_code.toLowerCase()} mr-3`
                "
              ></span>
              {{ item.dialling_code }}
            </template>
            <template v-slot:item="{ item }">
              <span
                :class="
                  `flag-icon flag-icon-${item.country_code.toLowerCase()} mr-3`
                "
              ></span>
              {{ item.country_name }}
            </template>
          </v-select>
        </v-flex>
        <v-flex
          xs12
          sm9
          md9
          d-flex
          align-center
          :class="$vuetify.breakpoint.smAndUp ? 'pl-sm-0 pl-md-0' : ''"
          class="pr-0"
        >
          <v-text-field
            v-model="phoneNumber"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'border-top-left-radius: 0; border-bottom-left-radius: 0;'
                : ''
            "
            name="phone"
            type="number"
            :label="$vuetify.lang.t('$vuetify.settings.phoneNumber')"
            outlined
            hide-details
            dense
            class="d-flex
            align-center"
          >
            <template v-slot:append-outer>
              <v-btn
                id="verify-number-button"
                outlined
                :loading="
                  loading.status === true &&
                    loading.type === 'updateCurrentUserWithData'
                "
                :disabled="!isValidPhoneNumber"
                @click="didClickAddPhoneNumber()"
              >
                {{
                  !user.phone
                    ? $vuetify.lang.t('$vuetify.settings.add')
                    : $vuetify.lang.t('$vuetify.settings.update')
                }}
              </v-btn>
            </template>
          </v-text-field>
        </v-flex>
      </v-layout>
    </v-fade-transition>
    <!-- <v-fade-transition v-if="verificationCodeSent">
      <v-layout row wrap>
        <v-flex xs12 md12 d-flex align-center>
          <v-text-field
            v-model="verificationCode"
            name="verification code"
            label="Verification code"
            outlined
            hide-details
            dense
            class="d-flex
            align-center"
          >
            <template v-slot:append-outer>
              <v-btn
                id="verify-number-button"
                outlined
                :loading="loading"
                @click="verifyPhoneAuthCode()"
              >
                Verify
              </v-btn>
            </template>
          </v-text-field>
        </v-flex>
      </v-layout>
    </v-fade-transition> -->
  </div>
</template>
<script>
import countries from '@/assets/countries'
import { mapGetters, mapActions } from 'vuex'
// import { firebase } from '@firebase/app'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

export default {
  name: 'PhoneSelect',
  data() {
    return {
      countries,
      selectedPhoneCountryCode: {
        country_code: 'EE',
        country_name: 'Estonia',
        dialling_code: '+372'
      },
      phoneNumber: '',
      verificationCode: null
      // appVerifier: null,
      // verificationCodeSent: false,
      // error: null,
      // successMessage: null
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
    ...mapGetters('authentication', ['user']),
    isValidPhoneNumber() {
      if (this.phoneNumber.length > 3 && this.phoneNumber.length <= 17) {
        try {
          const phoneNumberWithCountryCode =
            this.selectedPhoneCountryCode.dialling_code + this.phoneNumber
          const phoneNumber =
            this.phoneNumber !== ''
              ? parsePhoneNumberFromString(phoneNumberWithCountryCode)
              : ''
          return this.phoneNumber !== '' ? phoneNumber.isValid() : false
        } catch (error) {
          return false
        }
      }
      return false
    }
  },
  mounted() {
    if (this.user.phone !== null && this.user.phone !== '' && this.user.phone) {
      this.selectedPhoneCountryCode = this.countries.find(
        country => country.country_code === this.user.phone.country_code
      )
      this.phoneNumber = this.user.phone.number || ''
    }
    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   'verify-number-button',
    //   {
    //     size: 'invisible',
    //     callback() {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //     }
    //   }
    // )
    // this.appVerifier = window.recaptchaVerifier
  },
  methods: {
    ...mapActions('authentication', ['updateCurrentUserWithData']),
    didClickAddPhoneNumber() {
      // const appVerifier = window.recaptchaVerifier
      const diallingCode = this.selectedPhoneCountryCode.dialling_code
      const countryCode = this.selectedPhoneCountryCode.country_code
      const number = this.phoneNumber
      this.updateCurrentUserWithData({
        this: this,
        data: {
          phone: {
            dialling_code: diallingCode,
            number,
            country_code: countryCode
          }
        },
        updatedProperty: this.$vuetify.lang.t('$vuetify.settings.phoneNumber')
      })
    }
    // verifyPhoneAuthCode() {
    //   this.loading = true
    //   // eslint-disable-next-line no-undef
    //   confirmationResult
    //     .confirm(this.verificationCode)
    //     .then(result => {
    //       const { user } = result
    //       console.log(user)
    //       firebase
    //         .auth()
    //         .currentUser.linkWithPopup(new firebase.auth.PhoneAuthProvider())
    //         .then(function(result2) {
    //           // Accounts successfully linked.
    //           // const { credential } = result
    //           // const { user } = result
    //           console.log(result2)
    //           // ...
    //         })
    //         .catch(function(error) {
    //           // Handle Errors here.
    //           // ...
    //           console.log(error)
    //         })
    //       this.successMessage = 'Phone number added successfully.'
    //       this.error = null
    //       this.loading = false
    //     })
    //     .catch(error => {
    //       this.error = error
    //       console.log(error)
    //       this.loading = false
    //     })
    // }
  }
}
</script>
