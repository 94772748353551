import { render, staticRenderFns } from "./PhoneSelect.vue?vue&type=template&id=9df3cd88&"
import script from "./PhoneSelect.vue?vue&type=script&lang=js&"
export * from "./PhoneSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VFadeTransition,VFlex,VLayout,VSelect,VTextField})
