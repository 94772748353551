var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-fade-transition',{attrs:{"hide-on-leave":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","d-flex":"","align-center":""}},[_c('v-flex',{staticClass:"pl-0",class:_vm.$vuetify.breakpoint.smAndUp ? 'pr-0' : '',attrs:{"xs12":"","sm3":"","md3":""}},[_c('v-select',{style:(_vm.$vuetify.breakpoint.smAndUp
              ? 'border-top-right-radius: 0; border-bottom-right-radius: 0;'
              : ''),attrs:{"items":_vm.countries,"cache-items":"","label":_vm.$vuetify.lang.t('$vuetify.settings.countryCode'),"item-text":"country_name","item-value":"country_name","return-object":"","outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:("flag-icon flag-icon-" + (item.country_code.toLowerCase()) + " mr-3")}),_vm._v(" "+_vm._s(item.dialling_code)+" ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',{class:("flag-icon flag-icon-" + (item.country_code.toLowerCase()) + " mr-3")}),_vm._v(" "+_vm._s(item.country_name)+" ")]}}]),model:{value:(_vm.selectedPhoneCountryCode),callback:function ($$v) {_vm.selectedPhoneCountryCode=$$v},expression:"selectedPhoneCountryCode"}})],1),_c('v-flex',{staticClass:"pr-0",class:_vm.$vuetify.breakpoint.smAndUp ? 'pl-sm-0 pl-md-0' : '',attrs:{"xs12":"","sm9":"","md9":"","d-flex":"","align-center":""}},[_c('v-text-field',{staticClass:"d-flex\n          align-center",style:(_vm.$vuetify.breakpoint.smAndUp
              ? 'border-top-left-radius: 0; border-bottom-left-radius: 0;'
              : ''),attrs:{"name":"phone","type":"number","label":_vm.$vuetify.lang.t('$vuetify.settings.phoneNumber'),"outlined":"","hide-details":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"id":"verify-number-button","outlined":"","loading":_vm.loading.status === true &&
                  _vm.loading.type === 'updateCurrentUserWithData',"disabled":!_vm.isValidPhoneNumber},on:{"click":function($event){return _vm.didClickAddPhoneNumber()}}},[_vm._v(" "+_vm._s(!_vm.user.phone ? _vm.$vuetify.lang.t('$vuetify.settings.add') : _vm.$vuetify.lang.t('$vuetify.settings.update'))+" ")])]},proxy:true}]),model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }